import React from 'react';
import { connect } from 'react-redux';
import { getImgixUrl } from '../../../../../../../helpers';
import Link from '../../../../../../shared/Link/Link';
import staticMenuItems from '../staticMenuItems';
import './EventsListColumns.scss';

export function EventsListColumns(props) {
  const { pageConfig: { tenantId } = {} } = props;

  return (
    <div className="events-list-columns">
      {staticMenuItems.aboutItems.items.map((item, i) => {
        const { title, text, links = [], backgroundImage } = item;

        return (
          <div
            key={i}
            className="events-list-columns__item col-xs-12 col-md-6 col-sm-6 col-lg-3 lazy"
            data-background-image={getImgixUrl(tenantId, backgroundImage)}
          >
            <ul>
              <li>
                <Link
                  to={links[0] && links[0].href}
                  className="events-list-columns__link"
                  parent
                >
                  <span className="events-list-columns__link-line" />
                  <h3 className="events-list-columns__item-header">{title}</h3>
                  <p className="events-list-columns__item-content">{text}</p>
                </Link>
              </li>
              {links.map((link, j) => (
                <li className="events-list-columns__item-link" key={j}>
                  <Link
                    to={link.href}
                    className="events-list-columns__item-link-text"
                    parent
                  >
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(EventsListColumns);
