import React from 'react';
import PropTypes from 'prop-types';

import './LoadingBlock.scss';

const LoadingBlock = (props) => (
  <div
    className={`hub-menu-loading-block ${props.className}`}
    data-testid="loading-block"
  >
    {[1, 2, 3].map((key) => (
      <div key={key} className="group" data-testid="loading-block-group">
        {[1, 2, 3, 4].map((key) => (
          <div
            key={key}
            className="group-item"
            data-testid="loading-block-group-item"
          >
            <div className="loading" data-testid="loading-block-loading" />
          </div>
        ))}
      </div>
    ))}
  </div>
);

LoadingBlock.propTypes = {
  className: PropTypes.string,
};

export default LoadingBlock;
