import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// TODO: remove react-transition-group lib, it is not in production
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import staticMenuItems from '../staticMenuItems';
import EventsListTable from '../EventsListTable/EventsListTable';
import Link from '../../../../../../shared/Link/Link';

import './VerticalsList.scss';

export class VerticalsList extends Component {
  static propTypes = {
    hub: PropTypes.object,
    activePortfolio: PropTypes.string,
    changeActive: PropTypes.func,
    subMenuMobileClosed: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const { pageConfig: { portfolioId } = {} } = this.props;

    this.state = {
      isRebranding: ['AUTOMOTIVE', 'TMT', 'TELECOMSTECHACADEMY'].includes(
        portfolioId,
      ),
    };

    this.onClickVertical = this.onClickVertical.bind(this);
  }

  getSorted(list = []) {
    const filtered = list.filter((item) => item.type === 'portfolio');

    filtered.sort((a, b) => {
      if (a.name > b.name) return 1;
      return -1;
    });

    return filtered;
  }

  onClickVertical() {
    const { changeActive } = this.props;
    const { isRebranding } = this.state;

    return !isRebranding && changeActive()();
  }

  render() {
    const {
      hub: { data: { blocks } = {} } = {},
      activePortfolio,
      changeActive,
      subMenuMobileClosed,
    } = this.props;
    const { isRebranding } = this.state;
    const aboutKnectItem = staticMenuItems.aboutItems;
    const sortedList = this.getSorted(blocks).filter((item) =>
      ['AUTOMOTIVE', 'TMT', 'TELECOMSTECHACADEMY'].includes(item.domain),
    );

    const learningItem = () => {
      return (
        <li className="verticals-list__item">
          <Link
            to="https://telecomstechacademy.com/"
            className="verticals-list__item-title"
            gaNavbar
          >
            Learning
            <i className="verticals-list__item-icon material-icons">launch</i>
          </Link>
        </li>
      );
    };

    const informaConnectItem = () => {
      return (
        <li className="verticals-list__item">
          <Link
            to="https://informaconnect.com/"
            className="verticals-list__item-title"
            gaNavbar
          >
            Informa Connect
            <i className="verticals-list__item-icon material-icons">launch</i>
          </Link>
        </li>
      );
    };

    const menuItems = sortedList.map((item, i) => {
      const { domain } = item;
      const isActive = () => activePortfolio === domain;
      const verticalName = domain.toLowerCase();

      return (
        <li
          className={`verticals-list__item ${
            isActive() ? 'verticals-list__item--active' : ''
          } prevent-menu-closing`}
          onClick={changeActive(domain)}
          key={i}
        >
          <span
            className={`verticals-list__item-title hub-menu-border-color-${verticalName}
            ${
              isActive() ? `hub-menu-border-color-${verticalName}--active` : ''
            }`}
          >
            {item.name}
          </span>
          <TransitionGroup>
            {isActive() && !subMenuMobileClosed && (
              <CSSTransition classNames="submenu-mobile" timeout={500}>
                <EventsListTable portfolio={domain} isMobile />
              </CSSTransition>
            )}
          </TransitionGroup>
        </li>
      );
    });

    const mobileStaticSubItems = aboutKnectItem.items.map((item, i) => (
      <li key={i} className="verticals-list__subitem-mobile col-xs-12">
        <Link
          to={item.links[0].href}
          className="verticals-list__subitem-mobile-link"
          targer={item.links[0].target}
          gaNavbar
        >
          {item.title}
        </Link>
      </li>
    ));

    return (
      <ul className="verticals-list">
        <li
          className={`verticals-list__item prevent-menu-closing ${
            !activePortfolio ? 'verticals-list__item--active' : ''
          }`}
          onClick={this.onClickVertical}
        >
          <span
            className={`verticals-list__item hub-menu-border-color-about
            ${!activePortfolio ? 'hub-menu-border-color-about--active' : ''}
            ${
              isRebranding
                ? 'verticals-list__item'
                : 'verticals-list__item-title'
            }`}
          >
            {isRebranding ? (
              <Link
                to="https://informatech.com/"
                className="verticals-list__item-title"
                gaNavbar
              >
                {aboutKnectItem.data.titleTmt}
              </Link>
            ) : (
              aboutKnectItem.data.title
            )}
            <Link
              to={`${isRebranding ? 'https://tech.informa.com/' : '/'}`}
              parent
              className="verticals-list__item-button"
              gaNavbar
            >
              <i className="material-icons">{aboutKnectItem.data.button}</i>
            </Link>
          </span>
          <TransitionGroup>
            {!activePortfolio && !subMenuMobileClosed && (
              <CSSTransition classNames="submenu-mobile" timeout={500}>
                <ul
                  key={aboutKnectItem.data.title}
                  className="verticals-list__subitems-list-mobile row visible-xs"
                >
                  {mobileStaticSubItems}
                </ul>
              </CSSTransition>
            )}
          </TransitionGroup>
        </li>
        {menuItems}
        {learningItem()}
        {informaConnectItem()}
      </ul>
    );
  }
}

function mapStateToProps(state) {
  return {
    hub: state.hub,
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(VerticalsList);
