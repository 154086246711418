import React from 'react';
import PropTypes from 'prop-types';

import { renderHTMLString } from '../../../../../../../helpers';
import Link from '../../../../../../shared/Link/Link';

export default function EventsListTableRow(props) {
  const { list = [], rowName, portfolio } = props;
  const listClass =
    list.length === 1
      ? 'events-list-table__list--one'
      : list.length === 2
      ? 'events-list-table__list--two'
      : list.length === 3
      ? 'events-list-table__list--three'
      : '';

  return (
    <ul className={`events-list-table__list ${listClass}`}>
      {list.map((item, i) => {
        const { title, date, city, path, colour, button, descriptions, isAll } =
          item;
        const sub = rowName === 'events' || isAll ? portfolio : null;
        const parent = !sub;
        const viewAllClass = isAll ? ' events-list-table__item-more' : '';
        const itemPath =
          rowName === 'learning' && path ? path : path ? `/${path}/` : '';

        return (
          <li key={i} className={`events-list-table__item${viewAllClass}`}>
            <Link
              to={itemPath}
              sub={sub}
              parent={parent}
              className="events-list-table__item-link"
              gaNavbar
            >
              <span
                className="events-list-table__item-line"
                style={colour && { backgroundColor: `#${colour}` }}
              />
              <h3 className="events-list-table__item-title">
                {renderHTMLString(title)}
              </h3>
              {date && (
                <div className="events-list-table__item-date">
                  {renderHTMLString(date)}
                </div>
              )}
              {descriptions && (
                <div className="events-list-table__item-description">
                  {renderHTMLString(descriptions)}
                </div>
              )}
              {city && (
                <div className="events-list-table__item-city">{city}</div>
              )}
              {button && (
                <div className="events-list-table__item-button">{button}</div>
              )}
              {isAll && (
                <i className="fa fa-arrow-circle-right events-list-table__icon" />
              )}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

EventsListTableRow.propTypes = {
  list: PropTypes.array,
  rowName: PropTypes.string,
  portfolio: PropTypes.string,
};
