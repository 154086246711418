const staticMenuItems = {
  // About KNect365 menu section
  aboutItems: {
    data: {
      title: 'About',
      titleTmt: 'About',
      button: 'home',
    },
    items: [
      {
        title: 'About us',
        text: 'Our live events and digital services give people extraordinary opportunities to enhance their professional knowledge and networks.',
        links: [
          {
            title: 'Learn about us >',
            href: 'https://informatech.com/',
          },
        ],
        backgroundImage: '/images/corporate-menu-images/about-us.jpg',
      },
      {
        title: 'Our Story',
        text: 'The name may be new, but we’ve been around for a while.',
        links: [
          {
            title: 'Read our story >',
            href: '/about/our-story',
          },
        ],
        backgroundImage: '/images/corporate-menu-images/our-story.jpg',
      },
      {
        title: 'Join our team',
        text: 'We’re always looking for exceptional people who want to make a difference.',
        links: [
          {
            title: 'Careers >',
            href: '/about/careers',
          },
          {
            title: 'View our jobs >',
            href: '/about/careers/vacancies',
          },
        ],
        backgroundImage: '/images/corporate-menu-images/careers.jpg',
      },
      {
        title: 'Contact us',
        text: 'We are more than 1,000 people, working in more than 60 markets.',
        links: [
          {
            title: 'Office locations >',
            href: '/about/contact-us',
          },
          {
            title: 'Make an enquiry >',
            href: '/about/contact-us#contact_form',
          },
        ],
        backgroundImage: '/images/corporate-menu-images/contact-us.jpg',
      },
    ],
  },

  // Learning menu section
  learningItems: {
    ENERGY: [
      {
        title: 'International Faculty of Energy (IFE)',
        descriptions: 'Oil, Power, LNG',
        path: 'https://ife-training.com',
        button: 'ife-training.com',
      },
    ],
    FINANCE: [
      {
        title: 'International Faculty of Finance (IFF)',
        descriptions: 'Risk Management, Project Finance, Fintech',
        path: 'https://iff-training.com',
        button: 'iff-training.com',
      },
    ],
    MARKETING: [
      {
        title: 'Innovation Academy',
        descriptions:
          'Corporate Innovation, Digital Transformation, Artificial Intelligence',
        path: 'https://innovation-academy.co.uk',
        button: 'innovation-academy.co.uk',
      },
    ],
    LAW: [
      {
        title: 'KNect365 Learning',
        descriptions: 'Competition, Medical, Sports',
        path: 'http://www.courses.knect365-learning.com/filter?type=&sector=485141&location=&phrase=',
        button: 'courses.knect365-learning.com',
      },
    ],
    LIFESCIENCES: [
      {
        title: 'Pharma Training International (PTI)',
        descriptions:
          'Regulatory Affairs, Biopharmaceuticals, Clinical, Manufacturing',
        path: 'https://pti-global.co.uk',
        button: 'pti-global.co.uk',
      },
      {
        title: 'Medical Device Training International (MDTI)',
        descriptions: 'Regulatory Affairs, Clinical, Manufacturing',
        path: 'https://mdti-global.co.uk',
        button: 'mdti-global.co.uk',
      },
      {
        title: 'Agrochemical Training International (ATI)',
        descriptions: 'Regulatory Affairs, Manufacturing',
        path: 'https://ati-global.co.uk',
        button: 'ati-global.co.uk',
      },
    ],
    MARITIME: [
      {
        title: 'Lloyds Maritime Academy',
        descriptions: 'Maritime, Shipping, Law',
        path: 'https://lloydsmaritimeacademy.com',
        button: 'lloydsmaritimeacademy.com',
      },
    ],
    TMT: [
      {
        title: 'Telecoms &amp; Tech Academy',
        descriptions: 'Telecoms Business, 5G, Connected Innovation',
        path: 'https://telecomstechacademy.com',
        button: 'telecomstechacademy.com',
      },
    ],
  },
};

export default staticMenuItems;
