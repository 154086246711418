import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LoadingBlock from '../LoadingBlock/LoadingBlock';
import EventsListTableRow from '../EventsListTableRow/EventsListTableRow';

import staticMenuItems from '../staticMenuItems';

import './EventsListTable.scss';

export function EventsListTable(props) {
  const { hubMenu: { data: hubMenu = {} } = {}, portfolio, isMobile } = props;
  const visibilityClass = isMobile
    ? 'hidden-sm hidden-md hidden-lg'
    : 'hidden-xs';

  if (Object.keys(hubMenu).length === 0) {
    return <LoadingBlock className={visibilityClass} />;
  }

  const activePortfolio = portfolio && portfolio.toUpperCase();
  const currentVertical =
    hubMenu[activePortfolio] || hubMenu[Object.keys(hubMenu)[0]];

  const events =
    currentVertical.events && currentVertical.events.length
      ? currentVertical.events.slice()
      : undefined;
  const learning = staticMenuItems.learningItems[currentVertical.path];

  const content = {
    events,
    learning,
  };

  const viewAllItem = {
    path: '/',
    sub: currentVertical.path,
    title: 'View all',
    isAll: true,
  };

  if (content.events && currentVertical.showMoreEventsLink) {
    content.events.push(viewAllItem);
  }

  if (content.learning && currentVertical.showMoreLearningLink) {
    content.learning.push(viewAllItem);
  }

  return (
    <div
      className={`prevent-menu-closing events-list-table ${visibilityClass}`}
    >
      {Object.keys(content)
        .filter((key) => content[key] !== undefined)
        .map((rowName, i) => {
          const list = content[rowName];
          const listMaxLength = 4;
          const windowClass =
            list.length > listMaxLength ? ' scroll-window--has-more-link' : '';

          return (
            <div className={`events-list-table__${rowName}`} key={i}>
              <h2 className="events-list-table__header">{rowName}</h2>
              <div className="scroll-window-outer">
                <div className={`scroll-window${windowClass}`}>
                  <EventsListTableRow
                    list={list}
                    rowName={rowName}
                    portfolio={currentVertical.path}
                  />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

EventsListTable.propTypes = {
  hubMenu: PropTypes.object,
  portfolio: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default connect((state) => ({
  hubMenu: state.hubMenu,
}))(EventsListTable);
