import React, { Component } from 'react';
import { connect } from 'react-redux';
import VerticalsList from './VerticalsList/VerticalsList';
import EventsListColumns from './EventsListColumns/EventsListColumns';
import EventsListTable from './EventsListTable/EventsListTable';
import { fetchHubMenuAction } from '../../../../../../store/commonDucks/hubMenu';
import './HubKnect365HeaderMenu.scss';

export class HubKnect365HeaderMenu extends Component {
  constructor(props) {
    super(props);
    const { pageConfig: { portfolioId } = {} } = props;

    this.state = {
      activePortfolio: portfolioId,
      subMenuMobileClosed: true,
    };
  }

  componentDidMount() {
    if (this.props.fetchHubMenu) {
      this.props.fetchHubMenu();
    }
  }

  getEventsContent(activePortfolio) {
    if (activePortfolio) {
      return <EventsListTable portfolio={activePortfolio} isMobile={false} />;
    } else {
      return <EventsListColumns />;
    }
  }

  menuItemHandler = (activePortfolio) => () => {
    this.setState((state) => {
      const subMenuMobileClosed =
        activePortfolio !== state.activePortfolio
          ? false
          : !state.subMenuMobileClosed;

      return { activePortfolio, subMenuMobileClosed };
    });
  };

  render() {
    const { activePortfolio, subMenuMobileClosed } = this.state;

    return (
      <div className="drop-down-menu">
        <span className="drop-down-menu__wrapper">
          <VerticalsList
            activePortfolio={activePortfolio}
            changeActive={this.menuItemHandler}
            subMenuMobileClosed={subMenuMobileClosed}
          />
          {this.getEventsContent(activePortfolio)}
        </span>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchHubMenu() {
      return dispatch(fetchHubMenuAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HubKnect365HeaderMenu);
