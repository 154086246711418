import React, { Component } from 'react';
import PropTypes from 'prop-types';
// TODO: remove react-transition-group lib, it is not in production
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { allowScroll, preventScroll } from '../../../../../helpers';
import AccountWidget from '../../../../shared/AccountWidget/AccountWidget';
import HubHeaderMenu from './HubKnect365HeaderMenu/HubKnect365HeaderMenu';
import Link from '../../../../shared/Link/Link';

import './HubKnect365Header.scss';

export default class HubKnect365Header extends Component {
  static propTypes = {
    portfolio: PropTypes.string,
    portfolioShort: PropTypes.string,
    isBlackAndRedView: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      menuClosed: true,
    };

    this.menuButtonClick = this.menuButtonClick.bind(this);
  }

  menuButtonClick(event) {
    event.preventDefault();

    this.setState((state) => {
      if (state.menuClosed) {
        preventScroll();
      } else {
        allowScroll();
      }

      return { menuClosed: !state.menuClosed };
    });
  }

  overlayClick() {
    this.setState({
      menuClosed: true,
    });

    allowScroll();
  }

  menuClose(event) {
    let target = event.target;

    while (target.tagName !== 'HEADER') {
      if (/prevent-menu-closing/.test(target.className)) {
        return;
      }

      target = target.parentNode;
    }

    this.setState({
      menuClosed: true,
    });
    allowScroll();
  }

  getLogo(portfolioShort, portfolio) {
    switch (portfolio) {
      case 'AUTOMOTIVE':
      case 'TMT':
      case 'TELECOMSTECHACADEMY':
        return (
          <Link
            to="https://informatech.com/"
            target="_self"
            className="informa-tech-logo-header"
          >
            <h1 className="sr-only">Informa Tech</h1>
            <img
              src="/images/informa-techlogo-blue-2x.png"
              className="c-hub-footer__logo"
              alt="Informa Tech logo"
            />
          </Link>
        );
      default:
        return (
          <Link
            to="/"
            sub={portfolio}
            target="_self"
            className="tenant-logo-header"
          >
            <span className="tenant-logo-header__name">KNect</span>
            <span className="tenant-logo-header__name-secondary">365</span>
            {portfolioShort && (
              <div className="tenant-logo-header__portfolio-name">
                {portfolioShort}
              </div>
            )}
          </Link>
        );
    }
  }

  render() {
    const {
      portfolio,
      portfolioShort,
      isBlackAndRedView = 'DEFAULT',
    } = this.props;
    const { menuClosed } = this.state;

    const logo = this.getLogo(portfolioShort, portfolio);

    return (
      <div>
        <header
          id="header"
          className={`c-hub-header ${
            !menuClosed ? 'c-hub-header--menu-opened' : ''
          }`}
          onClick={(event) => this.menuClose(event)}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-7">
                <div className="c-hub-header__menu-container">
                  <span
                    className="menu-button button prevent-menu-closing"
                    onClick={this.menuButtonClick}
                  >
                    <i
                      className={`material-icons icon-open ${
                        !menuClosed ? 'hidden' : ''
                      }`}
                    >
                      menu
                    </i>
                    <i
                      className={`material-icons icon-close ${
                        menuClosed ? 'hidden' : ''
                      }`}
                    >
                      close
                    </i>
                    <span className="menu-button__text">MENU</span>
                  </span>
                  {logo}
                </div>
              </div>
              <div className="col-xs-5">
                <div className="registration">
                  {isBlackAndRedView && (
                    <AccountWidget>
                      <Link
                        to="/account/login"
                        parent
                        className="registration-button button button--transparent"
                        rel="noopener noreferrer"
                      >
                        Log in
                      </Link>
                      <Link
                        to="/account/register"
                        parent
                        className="registration-button button button--success hidden-xs"
                        rel="noopener noreferrer"
                      >
                        Sign up free
                      </Link>
                    </AccountWidget>
                  )}
                </div>
              </div>
            </div>
            <div className="menu-container row">
              <HubHeaderMenu closed={menuClosed} />
            </div>
          </div>
        </header>
        <TransitionGroup>
          {!menuClosed && (
            <CSSTransition classNames="hub-menu__overlay" timeout={500}>
              <div
                className="hub-menu__overlay"
                onClick={() => this.overlayClick()}
              />
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    );
  }
}
